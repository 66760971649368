:root {
  /* Theme */
  /* https://material.io/tools/color/#!/?view.left=0&view.right=1&primary.color=177f62&secondary.color=BDBDBD */

  --mdc-theme-primary: #011627 !important;
  --mdc-theme-primary-light: #2a3c4f;
  --mdc-theme-primary-dark: #000000;
  --mdc-theme-primary-text: #ffffff;

  --mdc-theme-secondary: #df2935 !important;
  --mdc-theme-secondary-light: #ff635f;
  --mdc-theme-secondary-dark: #a5000f;
  --mdc-theme-secondary-text: #ffffff;

  --color-gray-light: #ffffff;
  --color-gray-dark: #3d3b3c;
  --color-green: #27fb6b;
  --color-warning: var(--mdc-theme-secondary-dark);

  --color-light-background: #ffffff;
  --color-light-background-text: var(--mdc-theme-primary);
  --color-dark-background: #333333;
  --color-dark-background-text: #ffffff);

  /* Fonts */
  --font-book: 'antartican-mono', monospace;
  --font-bold: 'prohibition', Arial, Helvetica, sans-serif;

  /* themes */
  --color-background-crossfit: #9e0059;
  --color-foreground-crossfit: #ffffff;
  --color-background-powerlifting: #ff5400;
  --color-foreground-powerlifting: #ffffff;
  --color-background-stronglifts: #ffbd00;
  --color-foreground-stronglifts: #ffffff;
  --color-background-tabata: #1d4e89;
  --color-foreground-tabata: #ffffff;

  /* Dimensions */
  --dimensions-max-width: 500px;

  /* Misc */
  --border-radius: 4px;
  --flash-background: #ffffff;

  /* Forms */

  --color-form-input-background: #ffffff;
  --color-form-error: var(--mdc-theme-secondary-dark);
  --color-form-outline: var(--color-form-input-background);
  --color-form-list-color: #ffffff;
  --color-form-background-prepare: #ffbd00;
  --color-form-background-work: #1d4e89;
  --color-form-background-rest: rgba(0, 0, 0, 0.1);

  /* Sheet */
  --color-sheet-background: #ffffff;
  --color-sheet-color: var(--mdc-theme-primary-light);

  /* Period List */
  --button-border-width: 0.5px;
  --button-border-color: rgba(0, 0, 0, 0.3);

  /* Login */
  --color-google: #DB4437;
  --color-facebook: #3C5A99;

  /* Advertisement */
  --color-cta: #1d4e89;
}

body {
  color: var(--mdc-theme-primary-text);
  margin: 0;
  background: var(--color-gray-dark);
  font-display: swap;
  overflow-x: hidden;
  touch-action: pan-y;
  min-height: 100vh;
}

body[no-scroll='true'] {
  overflow-y: hidden;
}

body,
.text-book {
  font-family: var(--font-book);
  /* font-weight: 400; */
  font-weight: 500;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
.text-bold {
  font-family: var(--font-bold);
  font-weight: 400;
  font-style: normal;
}

a,
a:visited {
  color: inherit;
  text-decoration: underline;
}

#app-base {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 5rem);
}

#page-content {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

p {
  max-width: calc(100vw - 2rem);
}

.grow {
  flex-grow: 1;
}

.fixed-fab {
  position: fixed !important;
  right: 1rem;
  bottom: 1rem;
}

/* MDC Patches */
.mdc-button.accent {
  background-color: var(--mdc-theme-secondary) !important;
  color: var(--mdc-theme-secondary-text) !important;
}

.mdc-button--outlined:not(:disabled).light {
  border-color: var(--color-gray-light) !important;
  color: var(--mdc-theme-secondary-text) !important;
}

.mdc-text-field--focused .mdc-floating-label {
  color: var(--mdc-theme-primary-light) !important;
}

.mdc-button,
.mdc-text-field__input,
.mdc-text-field-helper-text,
.mdc-floating-label,
.mdc-form-field,
.mdc-list,
.mdc-list-item__secondary-text {
  font-family: var(--font-book) !important;
}

.mdc-checkbox__background {
  border-color: var(--color-gray-light) !important;
}

.mdc-checkbox--selected .mdc-checkbox__background {
  border-color: transparent !important;
}

.mdc-switch + label {
  margin-left: 0.5rem;
}
