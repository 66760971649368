#top-bar {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-areas: 'left center right';

  width: calc(100vw - 2rem);
  padding: 1rem;
  position: relative;
  z-index: 1;
}

#user-menu,
#back-button,
#logo,
#timer-data {
  display: flex;
  align-items: center;
}

#back-button,
#logo {
  grid-area: left;
  justify-items: start;
}

#title {
  grid-area: center;
  place-self: stretch;
}

#user-menu,
#timer-data {
  grid-area: right;
  justify-self: end;
}

#title {
  display: flex;
  justify-content: center;
  align-items: center;
}

#title h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  text-align: center;
}

#title h1.large {
  font-size: 10vw;
  line-height: 9vw;
}

@media (max-height: 480px) {
  #top-bar {
    padding: 0.25rem;
  }

  #title h1 {
    top: 0.25rem !important;
  }
}

@media (max-width: 480px) {
  #title h1 {
    font-size: 1.5rem;
    margin-top: -1px;
  }

  #top-bar h1.large {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

#logo {
  display: flex;
  align-items: center;
}

#logo a.text {
  font-family: var(--font-bold);
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;

  text-decoration: none;
}

#logo a.mdc-icon-button {
  display: none;
}

@media (max-width: 480px) {
  #top-bar h1.large {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  #logo a.text {
    display: none;
  }

  #logo a.mdc-icon-button {
    display: inherit;
  }
}

#user-menu button {
  background-size: cover;
  border-radius: 50%;
}

#user-menu li {
  padding: 0.5rem 1rem;
}

#timer-data {
  display: flex;
  align-items: center;
}

#timer-data .time {
  font-size: 1rem;
}
